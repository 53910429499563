<script>
import { mapGetters } from "vuex";

export default {
  props: {
    value: {
      required: false
    },
    label: {
      type: String,
      required: false
    },
    field: {
      type: String,
      required: false
    },
    placeholder: {
      type: String,
      required: false
    },
    description: {
      type: String,
      default: ""
    }
  },
  computed: {
    ...mapGetters("form", ["getFieldError", "fieldHasError", "isPosting"]),
    id() {
      return `input-${this.field}`;
    },
    inputValue: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      }
    }
  }
};
</script>
