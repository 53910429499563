import store from "@/store";

export const checkSecureLink = (type, to, next) => {
  return store
    .dispatch("me/checkSecureLink", {
      type: type,
      selector: to.params.selector,
      token: to.params.token
    })
    .then(() => next())
    .catch(() => {
      next({
        name: "invalid-link",
        params: { selector: to.params.selector, token: to.params.token }
      });
    });
};
