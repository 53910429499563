<template>
  <b-form-group
    :label="label"
    :label-for="id"
    :invalid-feedback="getFieldError(field)"
    :state="!fieldHasError(field)"
    :aria-required="required"
  >
    <b-form-input
      :placeholder="placeholder"
      :disabled="isPosting"
      :id="id"
      :type="type"
      v-model="inputValue"
    />
  </b-form-group>
</template>

<script>
import FormGroupWithValidationMixin from "@/components/FormGroups/FormGroupWithValidationMixin";

export default {
  mixins: [FormGroupWithValidationMixin],
  props: {
    type: {
      type: String,
      required: false,
      default: "text"
    },
    required: {
      type: Boolean,
      required: false,
      default: false
    }
  }
};
</script>
