<template>
  <button :disabled="disabled || isPosting" type="submit">
    {{ label }}
  </button>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  props: {
    label: {
      type: String,
      required: true
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  computed: {
    ...mapGetters("form", ["isPosting"])
  }
};
</script>
