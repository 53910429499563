<template>
  <div class="col-md-8 col-lg-6 ml-auto mr-auto">
    <form class="form" @submit.prevent="handleSubmit">
      <div class="card card-login">
        <div class="card-header ">
          <div class="card-header ">
            <h3 class="header text-center">
              <translate>Reset password</translate>
            </h3>
          </div>
        </div>
        <div class="card-body">
          <FormInput
            field="password"
            type="password"
            v-model="payload.password"
            :label="$gettext('Password')"
            required
          />
          <FormInput
            :disabled="isLoading"
            field="password_confirmation"
            type="password"
            v-model="payload.password_confirmation"
            :label="$gettext('Password Confirmation')"
            required
          />
          <SubmitButton
            :label="$gettext('Submit')"
            class="btn btn-info btn-block mb-3"
          />
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import FormInput from "@/components/FormGroups/FormInput";
import { checkSecureLink } from "@/router/hooks";
import SubmitButton from "@/components/SubmitButton";

export default {
  components: { SubmitButton, FormInput },
  props: {
    selector: {
      type: String,
      required: true
    },
    token: {
      type: String,
      required: true
    }
  },
  data: () => ({
    payload: { password: "", password_confirmation: "" },
    isLoading: false
  }),
  beforeRouteEnter(to, from, next) {
    checkSecureLink("forgot_password", to, next);
  },
  methods: {
    ...mapActions("me", ["resetPassword"]),
    ...mapActions("form", ["submit"]),
    handleSubmit() {
      const payload = {
        ...this.payload,
        selector: this.selector,
        token: this.token
      };
      this.submit(() => this.resetPassword(payload)).then(() => {
        this.$router.push({ name: "login" });
      });
    }
  }
};
</script>
